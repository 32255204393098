import React from 'react';

const ErrorPage = () => {
  return (
    <section className="hero">
      <div className="hero-body">
        <h1 className="title">Oops!</h1>
        <p className="subtitle">Sorry, an unexpected error has occurred.</p>
        <p>Back to Home: <a href="/dashboard">Click!</a></p>
      </div>
    </section>
  )
}

export default ErrorPage;