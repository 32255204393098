import React from "react";
import { IconContext } from "react-icons";
import { FaUserGraduate } from "react-icons/fa";
import { Outlet } from "react-router-dom";

const PendidikanRoot = () => {
  return (
    <section className="section is-fullwidth">
      <div className="labelPage2">
        <div className="iconLabel2">
          <IconContext.Provider
            value={{
              className: "icon is-large has-text-white",
            }}
          >
            <FaUserGraduate />
          </IconContext.Provider>
        </div>
        <div className="vertical-center">
          <h1 className="title ml-6 is-6 has-text-white">
            LAPORAN DATA PENDIDIKAN
          </h1>
          <p className="subtitle is-7 ml-6 has-text-white">
            Data semua laporan data pendidikan tes psikologi sim
          </p>
        </div>
      </div>
      <div className="boxCard">
        <Outlet />
      </div>
    </section>
  );
};

export default PendidikanRoot;
