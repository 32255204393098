/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from "axios";
import jwtDecode from "jwt-decode";
import Swal from "sweetalert2";
import { IconContext } from "react-icons";
import { useNavigate, Link } from "react-router-dom";
import {
  FaPhoneSquare,
  FaRegFilePdf,
  FaAlignJustify,
  FaMoneyBillWave,
  FaTrash,
  FaShare,
  FaUserGraduate,
  FaBookOpen,
  FaPoll,
  FaCalendarWeek,
  FaPenSquare,
  FaArchway,
} from "react-icons/fa";
import { API_URL_AUTH } from "../../config/config";

const LaporanIndex = () => {
  /* eslint-disable-next-line */
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    refreshToken();
  }, []);

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${API_URL_AUTH}/admin/refreshtoken`);
      setToken(response.data.token);
      const decoded = jwtDecode(response.data.token);
      setExpire(decoded.exp);
    } catch (error) {
      if (error.response) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
          timer: 2000,
          timerProgressBar: true,
          showConfirmButton: false,
          willClose: () => {
            navigate("/login");
          },
        }).then((result) => {
          if (result.dismiss === Swal.DismissReason.timer) {
            navigate("/login");
          }
        });
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${API_URL_AUTH}/admin/refreshtoken`);
        config.headers.Authorization = `Bearer ${response.data.token}`;
        setToken(response.data.token);
        const decoded = jwtDecode(response.data.token);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return (
    <>
      <section className="section is-fullwidth mb-4 bg-main">
        <div className="container">
          <div className="columns is-desktop">
            <div className="column is-6">
              <h4 className="has-text-white labelTitle has-icons-left">
                <span className="icon is-left">
                  <FaAlignJustify />
                </span>
                <span>Menu Utama</span>
              </h4>
              <p className="mb-3 is-size-7 has-text-white">
                Semua menu utama RHS
              </p>
              <div className="columns is-variable is-3-mobile is-1-desktop is-multiline is-desktop is-mobile">
                <div className="column is-3-desktop is-6-mobile">
                  <Link to="laporan-pendidikan" className="box menu-shortcut">
                    <div className="has-text-centered">
                      <IconContext.Provider
                        value={{
                          className: "icon is-medium has-text-white mb-2",
                        }}
                      >
                        <FaUserGraduate />
                      </IconContext.Provider>
                      <p className="is-size-7 has-text-white">
                        Laporan Pendidikan
                      </p>
                    </div>
                  </Link>
                </div>
                <div className="column is-3-desktop is-6-mobile">
                  <Link to="laporan-tes" className="box menu-shortcut">
                    <div className="has-text-centered">
                      <IconContext.Provider
                        value={{
                          className: "icon is-medium has-text-white mb-2",
                        }}
                      >
                        <FaBookOpen />
                      </IconContext.Provider>
                      <p className="is-size-7 has-text-white">Laporan Tes</p>
                    </div>
                  </Link>
                </div>
                <div className="column is-3-desktop is-6-mobile">
                  <Link to="paybatal" className="box menu-shortcut">
                    <div className="has-text-centered">
                      <IconContext.Provider
                        value={{
                          className: "icon is-medium has-text-white mb-2",
                        }}
                      >
                        <FaTrash />
                      </IconContext.Provider>
                      <p className="is-size-7 has-text-white">Data Batal</p>
                    </div>
                  </Link>
                </div>
                <div className="column is-3-desktop is-6-mobile">
                  <Link to="doceppsi" className="box menu-shortcut">
                    <div className="has-text-centered">
                      <IconContext.Provider
                        value={{
                          className: "icon is-medium has-text-white mb-2",
                        }}
                      >
                        <FaRegFilePdf />
                      </IconContext.Provider>
                      <p className="is-size-7 has-text-white">Data Eppsi</p>
                    </div>
                  </Link>
                </div>
                <div className="column is-3-desktop is-6-mobile">
                  <Link to="mutasi" className="box menu-shortcut">
                    <div className="has-text-centered">
                      <IconContext.Provider
                        value={{
                          className: "icon is-medium has-text-white mb-2",
                        }}
                      >
                        <FaShare />
                      </IconContext.Provider>
                      <p className="is-size-7 has-text-white">Data Mutasi</p>
                    </div>
                  </Link>
                </div>
                <div className="column is-3-desktop is-6-mobile">
                  <Link to="total-print" className="box menu-shortcut">
                    <div className="has-text-centered">
                      <IconContext.Provider
                        value={{
                          className: "icon is-medium has-text-white mb-2",
                        }}
                      >
                        <FaPoll />
                      </IconContext.Provider>
                      <p className="is-size-7 has-text-white">Total Print</p>
                    </div>
                  </Link>
                </div>
                <div className="column is-3-desktop is-6-mobile">
                  <Link to="tanggal-report" className="box menu-shortcut">
                    <div className="has-text-centered">
                      <IconContext.Provider
                        value={{
                          className: "icon is-medium has-text-white mb-2",
                        }}
                      >
                        <FaCalendarWeek />
                      </IconContext.Provider>
                      <p className="is-size-7 has-text-white">Tanggal Report</p>
                    </div>
                  </Link>
                </div>
                <div className="column is-3-desktop is-6-mobile">
                  <Link to="update-nilai" className="box menu-shortcut">
                    <div className="has-text-centered">
                      <IconContext.Provider
                        value={{
                          className: "icon is-medium has-text-white mb-2",
                        }}
                      >
                        <FaPenSquare />
                      </IconContext.Provider>
                      <p className="is-size-7 has-text-white">Update Nilai</p>
                    </div>
                  </Link>
                </div>
                <div className="column is-3-desktop is-6-mobile">
                  <Link to="total-konseling" className="box menu-shortcut">
                    <div className="has-text-centered">
                      <IconContext.Provider
                        value={{
                          className: "icon is-medium has-text-white mb-2",
                        }}
                      >
                        <FaPhoneSquare />
                      </IconContext.Provider>
                      <p className="is-size-7 has-text-white">
                        Total Konseling
                      </p>
                    </div>
                  </Link>
                </div>
                <div className="column is-3-desktop is-6-mobile">
                  <Link to="report-print" className="box menu-shortcut">
                    <div className="has-text-centered">
                      <IconContext.Provider
                        value={{
                          className: "icon is-medium has-text-white mb-2",
                        }}
                      >
                        <FaArchway />
                      </IconContext.Provider>
                      <p className="is-size-7 has-text-white">Report Print</p>
                    </div>
                  </Link>
                </div>
              </div>
              <h4 className="has-text-white labelTitle has-icons-left">
                <span className="icon is-left">
                  <FaAlignJustify />
                </span>
                <span>Menu Print</span>
              </h4>
              <p className="mb-3 is-size-7 has-text-white">
                Semua menu Print Psikologi
              </p>
              <div className="columns is-variable is-3-mobile is-1-desktop is-multiline is-desktop is-mobile">
                <div className="column is-3-desktop is-6-mobile">
                  <Link to="report-print" className="box menu-shortcut">
                    <div className="has-text-centered">
                      <IconContext.Provider
                        value={{
                          className: "icon is-medium has-text-white mb-2",
                        }}
                      >
                        <FaArchway />
                      </IconContext.Provider>
                      <p className="is-size-7 has-text-white">Report Print</p>
                    </div>
                  </Link>
                </div>
                {/* <div className="column is-3-desktop is-6-mobile">
                  <Link to="total-print" className="box menu-shortcut">
                    <div className="has-text-centered">
                      <IconContext.Provider
                        value={{
                          className: "icon is-medium has-text-white mb-2",
                        }}
                      >
                        <FaPoll />
                      </IconContext.Provider>
                      <p className="is-size-7 has-text-white">Total Print</p>
                    </div>
                  </Link>
                </div> */}
              </div>
              <h4 className="has-text-white labelTitle has-icons-left">
                <span className="icon is-left">
                  <FaAlignJustify />
                </span>
                <span>Menu Arsip</span>
              </h4>
              <p className="mb-3 is-size-7 has-text-white">Semua menu Arsip</p>
              <div className="columns is-variable is-3-mobile is-1-desktop is-multiline is-desktop is-mobile">
                <div className="column is-3-desktop is-6-mobile">
                  <Link to="pembayaran-arsip" className="box menu-shortcut">
                    <div className="has-text-centered">
                      <IconContext.Provider
                        value={{
                          className: "icon is-medium has-text-white mb-2",
                        }}
                      >
                        <FaMoneyBillWave />
                      </IconContext.Provider>
                      <p className="is-size-7 has-text-white">
                        Pembayaran Arsip
                      </p>
                    </div>
                  </Link>
                </div>
                <div className="column is-3-desktop is-6-mobile">
                  <Link to="laporan-tes-arsip" className="box menu-shortcut">
                    <div className="has-text-centered">
                      <IconContext.Provider
                        value={{
                          className: "icon is-medium has-text-white mb-2",
                        }}
                      >
                        <FaBookOpen />
                      </IconContext.Provider>
                      <p className="is-size-7 has-text-white">
                        Laporan Tes Arsip
                      </p>
                    </div>
                  </Link>
                </div>
                <div className="column is-3-desktop is-6-mobile">
                  <Link to="total-print-arsip" className="box menu-shortcut">
                    <div className="has-text-centered">
                      <IconContext.Provider
                        value={{
                          className: "icon is-medium has-text-white mb-2",
                        }}
                      >
                        <FaPoll />
                      </IconContext.Provider>
                      <p className="is-size-7 has-text-white">
                        Total Print Arsip
                      </p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LaporanIndex;
