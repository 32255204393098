/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from "axios";
import jwtDecode from "jwt-decode";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import { FaSync, FaSearch } from "react-icons/fa";
import { API_URL_AUTH } from "../../config/config";

export const ChildTable = React.memo(
  ({
    dateFirst,
    dateSecond,
    jenTesQuery,
    katSimQuery,
    namaOutlet,
    outletId,
  }) => {
    const [totalYa, setTotalYa] = useState(0);
    const [totalTidak, setTotalTidak] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const [token, setToken] = useState("");
    const [expire, setExpire] = useState("");

    useEffect(() => {
      refreshToken();
      getTotalByOutlet();
    }, []);

    const refreshToken = async () => {
      try {
        const response = await axios.get(`${API_URL_AUTH}/admin/refreshtoken`);
        setToken(response.data.token);
        const decoded = jwtDecode(response.data.token);
        setExpire(decoded.exp);
      } catch (error) {
        console.log(error.message);
      }
    };

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(
      async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
          const response = await axios.get(
            `${API_URL_AUTH}/admin/refreshtoken`
          );
          config.headers.Authorization = `Bearer ${response.data.token}`;
          setToken(response.data.token);
          const decoded = jwtDecode(response.data.token);
          setExpire(decoded.exp);
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    const getTotalByOutlet = async () => {
      setIsLoading(true);
      await axiosJWT
        .get(
          `https://srvpay1.psisim.net/admin/total-tesbuku-arsip?date_first=${dateFirst}&date_second=${dateSecond}&katsim_query=${katSimQuery}&jentes_query=${jenTesQuery}&outlet_query=${outletId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setIsLoading(false);
          setTotalYa(response.data.totalYa);
          setTotalTidak(response.data.totalTidak);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err.message);
        });
    };

    if (isLoading)
      return (
        <tr>
          <td colSpan={3}>Loading...</td>
        </tr>
      );

    return (
      <tr>
        <td>{namaOutlet}</td>
        <td>{totalYa}</td>
        <td>{totalTidak}</td>
      </tr>
    );
  }
);

const TesArsipIndex = () => {
  /* eslint-disable-next-line */
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");

  const navigate = useNavigate();

  const [kategoriSim, setKategoriSim] = useState("");
  const [jenTes, setJenTes] = useState("");
  const [tglMulai, setTglMulai] = useState("");
  const [tglAkhir, setTglAkhir] = useState("");

  const [outlet, setOutlet] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const btnLoading = isLoading ? "is-loading" : "";

  useEffect(() => {
    refreshToken();
    // allOutlet();
  }, []);

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${API_URL_AUTH}/admin/refreshtoken`);
      setToken(response.data.token);
      const decoded = jwtDecode(response.data.token);
      setExpire(decoded.exp);
    } catch (error) {
      if (error.response) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
          timer: 2000,
          timerProgressBar: true,
          showConfirmButton: false,
          willClose: () => {
            navigate("/login");
          },
        }).then((result) => {
          if (result.dismiss === Swal.DismissReason.timer) {
            navigate("/login");
          }
        });
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${API_URL_AUTH}/admin/refreshtoken`);
        config.headers.Authorization = `Bearer ${response.data.token}`;
        setToken(response.data.token);
        const decoded = jwtDecode(response.data.token);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const allOutlet = async () => {
    setIsLoading(true);
    await axiosJWT
      .get(`${API_URL_AUTH}/admin/all-outlet`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setIsLoading(false);
        setOutlet(response.data.data);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error.message);
      });
  };

  const searchData = (e) => {
    e.preventDefault();

    allOutlet();
  };

  if (isLoading) return <p>Mohon Tunggu...</p>;

  return (
    <>
      <nav className="breadcrumb is-small" aria-label="breadcrumbs">
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li className="is-active">
            <Link href="#" aria-current="page">
              Laporan Data Pendidikan
            </Link>
          </li>
        </ul>
      </nav>
      <form onSubmit={searchData} className="mb-5">
        <div className="columns is-desktop">
          <div className="column">
            <div className="field is-horizontal">
              <div className="field-label is-small">
                <label className="label">Tanggal</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <p className="control is-expanded">
                    <input
                      type="date"
                      className="input is-small"
                      value={tglMulai}
                      onChange={(e) => setTglMulai(e.target.value)}
                      placeholder="0000-00-00"
                    />
                  </p>
                </div>
                <div className="field">
                  <p className="control is-expanded">
                    <input
                      type="date"
                      className="input is-small"
                      value={tglAkhir}
                      min={tglMulai}
                      onChange={(e) => setTglAkhir(e.target.value)}
                      placeholder="0000-00-00"
                    />
                  </p>
                </div>
              </div>
            </div>
            <div className="field is-horizontal">
              <div className="field-body">
                <div className="field is-horizontal">
                  <div className="field-label is-small">
                    <label className="label">Filter by</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <p className="control">
                        <span className="select is-small is-fullwidth">
                          <select
                            value={kategoriSim}
                            onChange={(e) => setKategoriSim(e.target.value)}
                          >
                            <option value="">- Semua Kategori -</option>
                            <option value="A">SIM A</option>
                            <option value="B">SIM B</option>
                            <option value="C">SIM C</option>
                            <option value="D">SIM D</option>
                            <option value="AC">SIM AC</option>
                            <option value="BC">SIM BC</option>
                            <option value="1SIM">1 SIM</option>
                            <option value="2SIM">2 SIM</option>
                          </select>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="field is-horizontal">
              <div className="field-body">
                <div className="field is-horizontal">
                  <div className="field-label is-small">
                    <label className="label">Jenis Tes</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <p className="control">
                        <span className="select is-small is-fullwidth">
                          <select
                            value={jenTes}
                            onChange={(e) => setJenTes(e.target.value)}
                          >
                            <option value="REMAN">REMAN</option>
                            <option value="OFFLINE">OFFLINE/MANUAL</option>
                            <option value="LOKMAN">LOKMAN</option>
                          </select>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <p className="buttons is-fullwidth is-centered has-background-light pt-2">
          <button
            type="submit"
            className={`button is-info is-small is-rounded ${btnLoading}`}
          >
            <span className="icon">
              <FaSearch />
            </span>
            <span>Filter Data</span>
          </button>

          <a
            href="/laporan-tes-arsip"
            className="button is-link is-small is-rounded is-light is-right"
          >
            <span className="icon">
              <FaSync />
            </span>
            <span className="is-hidden-mobile">Refresh</span>
          </a>
        </p>
      </form>

      <div className="table-container">
        <table className="table is-striped is-bordered is-fullwidth is-hoverable is-size-7">
          <thead className="table-header">
            <tr>
              <th>Nama Outlet</th>
              <th>Total Menggunakan Buku Tes</th>
              <th>Total Tidak Menggunakan Buku Tes</th>
            </tr>
          </thead>
          <tbody>
            {outlet.length > 0 &&
              outlet.map((ot, index) => (
                <ChildTable
                  key={index}
                  namaOutlet={ot.namaOutlet}
                  outletId={ot.id}
                  dateFirst={tglMulai}
                  dateSecond={tglAkhir}
                  jenTesQuery={jenTes}
                  katSimQuery={kategoriSim}
                />
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TesArsipIndex;
