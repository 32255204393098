/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from "axios";
import jwtDecode from "jwt-decode";
import Swal from "sweetalert2";
import { useNavigate, Link, useParams } from "react-router-dom";
import {
  FaSave,
  FaTimes,
  FaMinusCircle,
  FaCheck,
  FaCalendar,
} from "react-icons/fa";
import { API_URL_AUTH } from "../../config/config";

const TglReportEdit = () => {
  /* eslint-disable-next-line */
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");
  const [errors, setErrors] = useState([]);

  const { id } = useParams();
  const [tglFirst, setTglFirst] = useState("");
  const [tglSecond, setTglSecond] = useState("");
  const [blnReport, setBlnReport] = useState("");
  const [orderReport, setOrderReport] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    refreshToken();
    getPetugasById();
  }, []);

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${API_URL_AUTH}/admin/refreshtoken`);
      setToken(response.data.token);
      const decoded = jwtDecode(response.data.token);
      setExpire(decoded.exp);
    } catch (error) {
      if (error.response) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
          timer: 2000,
          timerProgressBar: true,
          showConfirmButton: false,
          willClose: () => {
            navigate("/login");
          },
        }).then((result) => {
          if (result.dismiss === Swal.DismissReason.timer) {
            navigate("/login");
          }
        });
      }
    }
  };

  const getPetugasById = async () => {
    await axiosJWT
      .get(`https://srvpay1.psisim.net/admin/tglreport/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setTglFirst(response.data.data.tglFirst);
        setTglSecond(response.data.data.tglSecond);
        setBlnReport(response.data.data.blnReport);
        setOrderReport(response.data.data.orderReport);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  };

  const updateTglReport = async (e) => {
    e.preventDefault();

    await axiosJWT
      .put(
        `https://srvpay1.psisim.net/admin/tglreport/${id}`,
        {
          tglFirst: tglFirst,
          tglSecond: tglSecond,
          blnReport: blnReport,
          orderReport: orderReport,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setTglFirst("");
        setTglSecond("");
        setBlnReport("");
        setOrderReport("");
        setErrors([]);

        Swal.fire({
          title: "Sukses!",
          text: response.data.message,
          icon: "success",
          showCancelButton: true,
          confirmButtonText: "OK, Kembali ke menu!",
          cancelButtonText: "Tutup, Input data lagi!",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate(-1);
          }
        });
      })
      .catch((error) => {
        if (error.response.status === 422) {
          console.log(error.response.data.errors);
          setErrors(error.response.data.errors);
          Swal.fire({
            icon: "warning",
            title: "Oops...",
            text: error.response.data.message,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.response.data.message,
          });
        }
      });
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${API_URL_AUTH}/admin/refreshtoken`);
        config.headers.Authorization = `Bearer ${response.data.token}`;
        setToken(response.data.token);
        const decoded = jwtDecode(response.data.token);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const checkErrorInput = (errors, param) => {
    const found = errors.find((error) => error.param === param);
    if (found) {
      return found;
    } else {
      return false;
    }
  };

  return (
    <>
      <nav className="breadcrumb is-small" aria-label="breadcrumbs">
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/tanggal-report">Data Tanggal Report</Link>
          </li>
          <li className="is-active">
            <Link href="#" aria-current="page">
              Edit Tanggal Report
            </Link>
          </li>
        </ul>
      </nav>
      <div className="columns">
        <div className="column is-three-fifths">
          <form onSubmit={updateTglReport}>
            <div className="field is-horizontal">
              <div className="field-label is-small">
                <label className="label">Tanggal Mulai s/d Akhir</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <p className="control is-expanded has-icons-left has-icons-right">
                    <input
                      className="input is-small"
                      type="text"
                      value={tglFirst}
                      onChange={(e) => setTglFirst(e.target.value)}
                      placeholder="tanggal mulai"
                    />
                    <span className="icon is-small is-left">
                      <FaCalendar />
                    </span>
                    {checkErrorInput(errors, "tglFirst") ? (
                      <span className="icon is-small is-right has-text-danger">
                        <FaMinusCircle />
                      </span>
                    ) : (
                      <span className="icon is-small is-right">
                        <FaCheck />
                      </span>
                    )}
                  </p>
                </div>
                <div className="field">
                  <p className="control is-expanded has-icons-left has-icons-right">
                    <input
                      className="input is-small"
                      type="text"
                      value={tglSecond}
                      onChange={(e) => setTglSecond(e.target.value)}
                      placeholder="tanggal akhir"
                    />
                    <span className="icon is-small is-left">
                      <FaCalendar />
                    </span>
                    {checkErrorInput(errors, "tglSecond") ? (
                      <span className="icon is-small is-right has-text-danger">
                        <FaMinusCircle />
                      </span>
                    ) : (
                      <span className="icon is-small is-right">
                        <FaCheck />
                      </span>
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className="field is-horizontal">
              <div className="field-label is-small">
                <label className="label">Bulan Report</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <p className="control is-expanded has-icons-right">
                    <input
                      className="input is-small"
                      type="text"
                      value={blnReport}
                      onChange={(e) => setBlnReport(e.target.value)}
                      placeholder="Bulan Report"
                    />
                    {checkErrorInput(errors, "blnReport") ? (
                      <span className="icon is-small is-right has-text-danger">
                        <FaMinusCircle />
                      </span>
                    ) : (
                      <span className="icon is-small is-right">
                        <FaCheck />
                      </span>
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className="field is-horizontal">
              <div className="field-label is-small">
                <label className="label">Order Report</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <p className="control is-expanded has-icons-right">
                    <input
                      className="input is-small"
                      type="text"
                      value={orderReport}
                      onChange={(e) => setOrderReport(e.target.value)}
                      placeholder="Order Report"
                    />
                    {checkErrorInput(errors, "orderReport") ? (
                      <span className="icon is-small is-right has-text-danger">
                        <FaMinusCircle />
                      </span>
                    ) : (
                      <span className="icon is-small is-right">
                        <FaCheck />
                      </span>
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className="field is-horizontal">
              <div className="field-label">&nbsp;</div>
              <div className="field-body">
                <div className="field is-grouped">
                  <div className="control">
                    <button
                      type="submit"
                      className="button is-link is-small is-rounded"
                    >
                      <span className="icon">
                        <FaSave />
                      </span>
                      <span>Update Tanggal Report</span>
                    </button>
                  </div>
                  <div className="control">
                    <button
                      type="button"
                      onClick={() => navigate(-1)}
                      className="button is-danger is-light is-small is-rounded"
                    >
                      <span className="icon">
                        <FaTimes />
                      </span>
                      <span>Batalkan</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <hr />
          {errors.map((error, index) => (
            <p key={`error-${index}`} className="has-text-danger is-size-7">
              <FaMinusCircle /> {error.msg}
            </p>
          ))}
        </div>
      </div>
    </>
  );
};

export default TglReportEdit;
