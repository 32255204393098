/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import jwtDecode from "jwt-decode";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import {
  FaSync,
  FaSearch,
  FaTrash,
  FaFileExcel,
  FaCog,
  FaHandPointUp,
} from "react-icons/fa";
import { API_URL_AUTH } from "../../config/config";

const PembayaranIndex = () => {
  /* eslint-disable-next-line */
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");

  const [urlApi, setUrlApi] = useState("https://srvpay1.psisim.net");
  const [urlApiIn, setUrlApiIn] = useState("https://srvpay1.psisim.net");
  const [showSet, setShowSet] = useState(false);
  const [isConfUrl, setIsConfUrl] = useState(false);
  const btlConfUrl = isConfUrl ? "is-loading" : "";

  const navigate = useNavigate();

  const [payment, setPayment] = useState([]);
  const [page, setPage] = useState(0);
  const [show, setShow] = useState(false);

  const [tglMulai, setTglMulai] = useState("");
  const [tglAkhir, setTglAkhir] = useState("");
  const [kategoriSim, setKategoriSim] = useState("");
  const [jenSim, setJenSim] = useState("");
  const [jenPemohon, setJenPemohon] = useState("");
  const [jenTes, setJenTes] = useState("");
  const [alamat, setAlamat] = useState("");
  const [noHp, setNoHp] = useState("");
  const [kwn, setKwn] = useState("");
  const [jenBayar, setJenBayar] = useState("");
  const [idOutlet, setIdOutlet] = useState("");

  const [outlet, setOutlet] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const btnLoading = isLoading ? "is-loading" : "";

  const [isDownload, setIsDownload] = useState(false);
  const btnDownload = isDownload ? "is-loading" : "";

  /* eslint-disable-next-line */
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [field, setField] = useState("nik");
  const [fieldQuery, setFieldQuery] = useState("nik");
  const [dateFirst, setDateFirst] = useState("");
  const [dateSecond, setDateSecond] = useState("");
  const [katSimQuery, setKatSimQuery] = useState("");
  const [jenSimQuery, setJenSimQuery] = useState("");
  const [jenPemohonQuery, setJenPemohonQuery] = useState("");
  const [kwnQuery, setKwnQuery] = useState("");
  const [idOutletQuery, setIdOutletQuery] = useState("");
  const [jenTesQuery, setJenTesQuery] = useState("");
  const [jenBayarQuery, setJenBayarQUery] = useState("");

  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
  const [msg, setMsg] = useState("");

  useEffect(() => {
    refreshToken();
    getAllPembayaran();
    allOutlet();
  }, [
    page,
    urlApi,
    keyword,
    fieldQuery,
    dateFirst,
    dateSecond,
    katSimQuery,
    jenSimQuery,
    jenPemohonQuery,
    kwnQuery,
    idOutletQuery,
    jenTesQuery,
    jenBayarQuery,
  ]);

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${API_URL_AUTH}/admin/refreshtoken`);
      setToken(response.data.token);
      const decoded = jwtDecode(response.data.token);
      setExpire(decoded.exp);
    } catch (error) {
      if (error.response) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
          timer: 2000,
          timerProgressBar: true,
          showConfirmButton: false,
          willClose: () => {
            navigate("/login");
          },
        }).then((result) => {
          if (result.dismiss === Swal.DismissReason.timer) {
            navigate("/login");
          }
        });
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${API_URL_AUTH}/admin/refreshtoken`);
        config.headers.Authorization = `Bearer ${response.data.token}`;
        setToken(response.data.token);
        const decoded = jwtDecode(response.data.token);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getAllPembayaran = async () => {
    setIsLoading(true);
    setIsConfUrl(true);
    console.log(urlApi);
    await axiosJWT
      .get(
        `${urlApi}/admin/pembayaran-arsip?field_query=${fieldQuery}&search_query=${keyword}&date_first=${dateFirst}&date_second=${dateSecond}&kategori_sim=${katSimQuery}&jen_sim=${jenSimQuery}&jen_pemohon=${jenPemohonQuery}&kwn_pemohon=${kwnQuery}&outlet=${idOutletQuery}&jentes=${jenTesQuery}&jenbayar=${jenBayarQuery}&page=${page}&limit=${limit}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setIsLoading(false);
        setIsConfUrl(false);
        setShowSet(false);
        setPayment(response.data.data);
        setPage(response.data.page);
        setPages(response.data.totalPage);
        setRows(response.data.totalRows);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const allOutlet = async () => {
    await axiosJWT
      .get(`${API_URL_AUTH}/admin/all-outlet`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setOutlet(response.data.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const downloadCsv = async () => {
    setIsDownload(true);
    await axiosJWT
      .get(
        `${urlApi}/admin/download-pembayaran?field_query=${fieldQuery}&search_query=${keyword}&date_first=${dateFirst}&date_second=${dateSecond}&kategori_sim=${katSimQuery}&jen_sim=${jenSimQuery}&jen_pemohon=${jenPemohonQuery}&outlet=${idOutletQuery}&jentes=${jenTesQuery}&jenbayar=${jenBayarQuery}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        axios
          .get(`${urlApi}/csv-file/${response.data.data}`, {
            responseType: "blob",
            headers: {
              "Content-Disposition": "attachment;filename=report.xls",
              "Content-Type": "text/csv",
            },
          })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "report.csv");
            document.body.appendChild(link);
            link.click();

            setIsDownload(false);
            Swal.fire({
              icon: "success",
              title: "Success Download",
              text: "Berhasil mendownload csv",
              timer: 2000,
              timerProgressBar: true,
              showConfirmButton: false,
            });
          });
      })
      .catch((error) => {
        setIsDownload(false);
        Swal.fire({
          icon: "error",
          title: "Error Download",
          text: error.response.data.message,
          timer: 2000,
          timerProgressBar: true,
          showConfirmButton: false,
        });
      });
  };

  const deletePayment = async (id) => {
    Swal.fire({
      title: "Konfirmasi Batal?",
      text: "Yakin ingin membatalkan pembayaran!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Batalkan!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await axiosJWT
          .delete(`${urlApi}/admin/pembayaran/${id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            getAllPembayaran();
            deleteRelation(id, "https://apipay30.psisimku.id");
            deleteRelation(id, "https://apipay70.psisimku.id");
            updatePemohon(id, "https://apites-psisimku.psisim.net");
            Swal.fire("Deleted!", response.data.message, "success");
          })
          .catch((error) => {
            Swal.fire("Deleted!", error.response.data.message, "error");
          });
      }
    });
  };

  const deleteRelation = async (id, url) => {
    await axiosJWT.delete(`${url}/admin/pembayaran/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const updatePemohon = async (id, url) => {
    await axios.put(`${url}/admin/update-pembayaran/${id}`);
  };

  const changePage = ({ selected }) => {
    setPage(selected);
    if (selected === 9) {
      setMsg(
        "Jika tidak menemukan data yang Anda cari, silahkan cari data dengan kata kunci spesifik!"
      );
    } else {
      setMsg("");
    }
  };

  const convertDate = (date) => {
    let dateConv = new Date(date);
    var milliseconds = dateConv.getTime();

    return milliseconds;
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setMsg("");
    setKeyword(query);
    setFieldQuery(field);
    setShow(true);

    setDateFirst(convertDate(tglMulai));
    setDateSecond(convertDate(tglAkhir));
    setKatSimQuery(kategoriSim);
    setJenSimQuery(jenSim);
    setJenPemohonQuery(jenPemohon);
    setKwnQuery(kwn);
    setIdOutletQuery(idOutlet);
    setJenTesQuery(jenTes);
    setNoHp(noHp);
    setAlamat(alamat);
    setJenBayarQUery(jenBayar);
  };

  const confUrlApi = (e) => {
    e.preventDefault();
    setPage(0);
    setUrlApi(urlApiIn);
  };

  return (
    <>
      <div className="is-flex is-flex-direction-row is-justify-content-space-between is-align-content-center mb-2">
        <nav className="breadcrumb is-small" aria-label="breadcrumbs">
          <ul>
            <li className="is-active">
              <Link href="#" aria-current="page">
                Data Pembayaran
              </Link>
            </li>
            <li className="is-active">
              <Link href="#" aria-current="page" className="has-text-danger">
                {urlApi}
              </Link>
            </li>
          </ul>
        </nav>
        <div>
          {showSet ? (
            <form onSubmit={confUrlApi}>
              <div className="field is-horizontal">
                <div className="field-body">
                  <div className="field">
                    <p className="control is-expanded">
                      <span className="select is-small is-fullwidth">
                        <select
                          value={urlApiIn}
                          onChange={(e) => setUrlApiIn(e.target.value)}
                        >
                          <option value="https://srvpay1.psisim.net">
                            GET URL DATA 100%
                          </option>
                        </select>
                      </span>
                    </p>
                  </div>
                  <div className="field">
                    <p className="control is-expanded">
                      <button
                        type="submit"
                        className={`button is-warning is-rounded is-small ${btlConfUrl}`}
                      >
                        <span className="icon">
                          <FaHandPointUp />
                        </span>
                        <span className="is-hidden-mobile">Simpan</span>
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </form>
          ) : (
            <button
              type="button"
              onClick={() => setShowSet(!showSet)}
              className={`button is-danger is-rounded is-small`}
            >
              <span className="icon">
                <FaCog />
              </span>
              <span className="is-hidden-mobile">Setting</span>
            </button>
          )}
        </div>
      </div>
      <form onSubmit={searchData} className="mb-5">
        <div className="columns is-desktop">
          <div className="column">
            <div className="field is-horizontal">
              <div className="field-label is-small">
                <label className="label">Search by</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <p className="control">
                    <span className="select is-small is-fullwidth">
                      <select
                        value={field}
                        onChange={(e) => setField(e.target.value)}
                      >
                        <option value="nik">NIK</option>
                        <option value="noTransaksi">No. Transaksi</option>
                        <option value="nomorTes">No. Tes</option>
                        <option value="namaPemohon">Nama Pemohon</option>
                        <option value="noHp">Nomor HP</option>
                        <option value="alamat">Alamat</option>
                        <option value="namaUser">Nama Petugas</option>
                      </select>
                    </span>
                  </p>
                </div>
                <div className="field">
                  <p className="control is-expanded">
                    <input
                      type="text"
                      className="input is-small"
                      value={query}
                      onChange={(e) => setQuery(e.target.value)}
                      placeholder="keyword..."
                    />
                  </p>
                </div>
              </div>
            </div>
            <div className="field is-horizontal">
              <div className="field-label is-small">
                <label className="label">Tanggal</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <p className="control is-expanded">
                    <input
                      type="datetime-local"
                      className="input is-small"
                      value={tglMulai}
                      onChange={(e) => setTglMulai(e.target.value)}
                      placeholder="0000-00-00 00:00:00"
                    />
                  </p>
                </div>
                <div className="field">
                  <p className="control is-expanded">
                    <input
                      type="datetime-local"
                      className="input is-small"
                      value={tglAkhir}
                      min={tglMulai}
                      onChange={(e) => setTglAkhir(e.target.value)}
                      placeholder="0000-00-00 00:00:00"
                    />
                  </p>
                </div>
              </div>
            </div>
            <div className="field is-horizontal">
              <div className="field-label is-small">
                <label className="label">Filter by</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <p className="control">
                    <span className="select is-small is-fullwidth">
                      <select
                        value={kategoriSim}
                        onChange={(e) => setKategoriSim(e.target.value)}
                      >
                        <option value="">- Semua Kategori -</option>
                        <option value="A">SIM A</option>
                        <option value="B">SIM B</option>
                        <option value="C">SIM C</option>
                        <option value="D">SIM D</option>
                        <option value="AC">SIM AC</option>
                        <option value="BC">SIM BC</option>
                        <option value="1SIM">1 SIM</option>
                        <option value="2SIM">2 SIM</option>
                      </select>
                    </span>
                  </p>
                </div>
                <div className="field">
                  <p className="control">
                    <span className="select is-small is-fullwidth">
                      <select
                        value={jenSim}
                        onChange={(e) => setJenSim(e.target.value)}
                      >
                        <option value="">- Semua Jenis -</option>
                        <option value="Baru">Baru</option>
                        <option value="Perpanjangan">Perpanjangan</option>
                        <option value="Peningkatan">Peningkatan</option>
                        <option value="Penurunan">Penurunan</option>
                        <option value="Hilang">Hilang</option>
                        <option value="Rusak">Rusak</option>
                      </select>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="field is-horizontal">
              <div className="field-label is-small">
                <label className="label">Jen.Tes & Jen.Bayar</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <p className="control">
                    <span className="select is-small is-fullwidth">
                      <select
                        value={jenTes}
                        onChange={(e) => setJenTes(e.target.value)}
                      >
                        <option value="">- Semua Jenis Tes -</option>
                        <option value="OFFLINE">OFFLINE</option>
                        <option value="REMAN">REMAN</option>
                        <option value="LOKMAN">LOKMAN</option>
                        <option value="PSIMAN">PSIMAN</option>
                      </select>
                    </span>
                  </p>
                </div>
                <div className="field">
                  <p className="control">
                    <span className="select is-small is-fullwidth">
                      <select
                        value={jenBayar}
                        onChange={(e) => setJenBayar(e.target.value)}
                      >
                        <option value="">- Semua Jenis Bayar -</option>
                        <option value="CASH">CASH</option>
                        <option value="EPAYMENT">EPAYMENT</option>
                      </select>
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="field is-horizontal">
              <div className="field-label is-small">
                <label className="label">Outlet</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <p className="control">
                    <span className="select is-small is-fullwidth">
                      <select
                        value={idOutlet}
                        onChange={(e) => setIdOutlet(e.target.value)}
                      >
                        <option key={`peru-00`} value="">
                          - Semua Outlet -
                        </option>
                        {outlet.map((outlet, index) => (
                          <option key={`peru-${index}`} value={outlet.id}>
                            {outlet.namaOutlet}
                          </option>
                        ))}
                      </select>
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="field is-horizontal">
              <div className="field-label is-small">
                <label className="label">Jen. Pemohon</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <p className="control">
                    <span className="select is-small is-fullwidth">
                      <select
                        value={jenPemohon}
                        onChange={(e) => setJenPemohon(e.target.value)}
                      >
                        <option value="">- Semua Jenis Pemohon -</option>
                        <option value="UMUM">UMUM</option>
                        <option value="ATENSI">ATENSI</option>
                        <option value="SP">SP</option>
                        <option value="VIP">VIP</option>
                      </select>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <p className="buttons is-fullwidth is-centered has-background-light pt-2">
          <button
            type="submit"
            className={`button is-info is-small is-rounded ${btnLoading}`}
          >
            <span className="icon">
              <FaSearch />
            </span>
            <span>Filter Data</span>
          </button>
          <button
            type="button"
            onClick={() => downloadCsv()}
            className={`button is-success is-light is-rounded is-small ${btnDownload}`}
          >
            <span className="icon">
              <FaFileExcel />
            </span>
            <span className="is-hidden-mobile">Download CSV</span>
          </button>
          <a
            href="/pembayaran-arsip"
            className="button is-link is-small is-rounded is-light is-right"
          >
            <span className="icon">
              <FaSync />
            </span>
            <span className="is-hidden-mobile">Refresh</span>
          </a>
        </p>
      </form>

      {show ? (
        <>
          {payment ? (
            <>
              <div className="table-container">
                <table className="table is-striped is-bordered is-fullwidth is-hoverable is-size-7">
                  <thead className="table-header">
                    <tr>
                      <th>No.</th>
                      <th>No. Transaksi</th>
                      <th>No. Tes</th>
                      <th>Pemohon</th>
                      <th>No.HP / Alamat</th>
                      <th>SIM</th>
                      <th>Jen. SIM</th>
                      <th>Jen. Tes</th>
                      <th>Print</th>
                      <th>Jen. Bayar</th>
                      <th>Biaya</th>
                      <th>PPN</th>
                      <th>Service</th>
                      <th>Total Bayar</th>
                      <th>Created At</th>
                      <th>Aksi</th>
                    </tr>
                  </thead>
                  <tbody>
                    {payment.map((payment, index) => (
                      <tr key={payment.id}>
                        <td>{page * limit + index + 1}</td>
                        <td>
                          <strong>{payment.noTransaksi}</strong>
                          <br />
                          ------
                          <br />
                          {payment.namaOutlet}
                          <br />
                          {payment.namaUser}
                        </td>
                        <td>
                          {payment.nomorTes}
                          <br />
                          ------
                          <br />
                          <strong>{payment.jenPemohon}</strong>
                        </td>
                        <td>
                          <strong>{payment.namaPemohon}</strong>
                          <br />
                          {payment.nik}
                          <br />
                          ------
                          <br />
                          {payment.tmpLahir}, {payment.tglLahir}
                        </td>
                        <td>
                          <strong>{payment.noHp}</strong>
                          <br />
                          ------
                          <br />
                          {payment.alamat}
                        </td>
                        <td>{payment.kategoriSim}</td>
                        <td>
                          {payment.jenSim}
                          {payment.noteSim && payment.noteSim.length > 0
                            ? payment.noteSim.map((sim, index) => (
                                <div
                                  key={index}
                                  style={{ marginBottom: "8px" }}
                                >
                                  <span>
                                    ({sim.katSim}) {sim.jenSim}
                                  </span>
                                </div>
                              ))
                            : ""}
                        </td>
                        <td>{payment.jenTes}</td>
                        <td>{payment.totalPrint}x</td>
                        <td>{payment.jenBayar}</td>
                        <td>{payment.totalBiaya}</td>
                        <td>{payment.ppn}</td>
                        <td>{payment.service}</td>
                        <td>{payment.totalBayar}</td>
                        <td>{payment.createdAt}</td>
                        <td>
                          <button
                            key={`del-${index}`}
                            onClick={() => deletePayment(payment.id)}
                            className="button is-small is-rounded is-danger is-light"
                          >
                            <span className="icon">
                              <FaTrash />
                            </span>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <p className="has-text-centered has-text-danger">{msg}</p>
              <div className="columns">
                <div className="column">
                  <span className="is-size-7">
                    Total Rows: {rows} Page: {rows ? page + 1 : 0} of {pages}
                  </span>
                </div>
                <div className="column">
                  <nav
                    className="pagination is-rounded is-small is-right"
                    key={rows}
                    role="navigation"
                    aria-label="pagination"
                  >
                    <ReactPaginate
                      previousLabel={"Prev"}
                      nextLabel={"Next"}
                      pageCount={Math.min(10, pages)}
                      onPageChange={changePage}
                      containerClassName={"pagination-list"}
                      pageLinkClassName={"pagination-link"}
                      previousLinkClassName={"pagination-previous"}
                      nextLinkClassName={"pagination-next"}
                      activeLinkClassName={"pagination-link is-current"}
                      disabledLinkClassName={"pagination-link is-disabled"}
                    />
                  </nav>
                </div>
              </div>
            </>
          ) : (
            <div className="is-fullwidth has-text-centered mt-5">
              <figure className="image is-2by2 is-inline-block">
                <img
                  src="https://plan.literasikitaindonesia.com/kasir/no-data-found.png"
                  alt="logo cart"
                />
              </figure>
              <h4>Data tidak ditemukan</h4>
            </div>
          )}
        </>
      ) : (
        <div className="is-fullwidth has-text-centered mt-5">
          <img
            src="https://plan.literasikitaindonesia.com/psisim/please-filter2.png"
            alt="logo cart"
            width={380}
          />
          <h4>Silahkan filter data terlebih dahulu</h4>
        </div>
      )}
    </>
  );
};

export default PembayaranIndex;
