/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import jwtDecode from "jwt-decode";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import { FaPenSquare, FaSearch } from "react-icons/fa";
import { API_URL_AUTH } from "../../config/config";

const UpdateNilaiIndex = () => {
  /* eslint-disable-next-line */
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const btnLoading = isLoading ? "is-loading" : "";

  const [tglreport, setTglReport] = useState([]);
  const [page, setPage] = useState(0);
  /* eslint-disable-next-line */
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [msg, setMsg] = useState("");

  useEffect(() => {
    refreshToken();
    getAllTglReport();
  }, [page]);

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${API_URL_AUTH}/admin/refreshtoken`);
      setToken(response.data.token);
      const decoded = jwtDecode(response.data.token);
      setExpire(decoded.exp);
    } catch (error) {
      if (error.response) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
          timer: 2000,
          timerProgressBar: true,
          showConfirmButton: false,
          willClose: () => {
            navigate("/login");
          },
        }).then((result) => {
          if (result.dismiss === Swal.DismissReason.timer) {
            navigate("/login");
          }
        });
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${API_URL_AUTH}/admin/refreshtoken`);
        config.headers.Authorization = `Bearer ${response.data.token}`;
        setToken(response.data.token);
        const decoded = jwtDecode(response.data.token);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getAllTglReport = async () => {
    await axiosJWT
      .get(
        `https://srvpay1.psisim.net/admin/tglreport?page=${page}&limit=${limit}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setTglReport(response.data.data);
        setPage(response.data.page);
        setPages(response.data.totalPage);
        setRows(response.data.totalRows);
      });
  };

  const deleteOutlet = async (id) => {
    Swal.fire({
      title: "Konfirmasi Hapus?",
      text: "Yakin ingin menghapus data!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, hapus data!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await axiosJWT
          .delete(`https://srvpay1.psisim.net/admin/tglreport/${id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            getAllTglReport();
            Swal.fire("Deleted!", response.data.message, "success");
          })
          .catch((error) => {
            Swal.fire("Deleted!", error.response.data.message, "error");
          });
      }
    });
  };

  const changePage = ({ selected }) => {
    setPage(selected);
    if (selected === 9) {
      setMsg(
        "Jika tidak menemukan data yang Anda cari, silahkan cari data dengan kata kunci spesifik!"
      );
    } else {
      setMsg("");
    }
  };

  return (
    <>
      <nav className="breadcrumb is-small" aria-label="breadcrumbs">
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li className="is-active">
            <Link href="#" aria-current="page">
              Data Tanggal Report
            </Link>
          </li>
        </ul>
      </nav>
      <div className="field is-horizontal">
        <div className="field-label is-small">
          <label className="label">Pilih Tanggal</label>
        </div>
        <div className="field-body">
          <div className="field">
            <p className="control is-expanded">
              <input
                type="date"
                className="input is-small"
                // value={tglAkhir}
                // min={tglMulai}
                // onChange={(e) => setTglAkhir(e.target.value)}
                placeholder="0000-00-00"
              />
            </p>
          </div>
          <button
            type="submit"
            className={`button is-info is-small ${btnLoading}`}
          >
            <span className="icon">
              <FaSearch />
            </span>
            <span>Cari</span>
          </button>
        </div>
      </div>

      <div class="columns is-mobile is-centered px-6 pt-6">
        <div class="column is-half">
          <p>Total Belum Di Update</p>
          <div className="card p-6">Total SIM :</div>
        </div>
        <div class="column is-half">
          <p>Total Belum Di Update</p>
          <div className="card p-6">Total SIM :</div>
        </div>
      </div>

      <p className="buttons is-fullwidth is-centered pt-2">
        <button
          type="submit"
          className={`button is-primary is-small ${btnLoading}`}
        >
          <span className="icon">
            <FaPenSquare />
          </span>
          <span>Update Nilai</span>
        </button>
      </p>
    </>
  );
};

export default UpdateNilaiIndex;
