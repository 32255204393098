import React from "react";
import { IconContext } from "react-icons";
import { FaCalendarWeek } from "react-icons/fa";
import { Outlet, NavLink, useLocation, Link } from "react-router-dom";

const TglReportRoot = () => {
  let location = useLocation();

  const getNavLinkClass = (path) => {
    return location.pathname === path ? "is-active" : "";
  };
  return (
    <section className="section is-fullwidth">
      <div className="labelPage2">
        <div className="iconLabel2">
          <IconContext.Provider
            value={{
              className: "icon is-large has-text-white",
            }}
          >
            <FaCalendarWeek />
          </IconContext.Provider>
        </div>
        <div className="vertical-center">
          <h1 className="title ml-6 is-6 has-text-white">TANGGAL REPORT</h1>
          <p className="subtitle is-7 ml-6 has-text-white">
            Data semua data tanggal report
          </p>
        </div>
      </div>
      <div className="boxCard">
        <div className="container">
          <div className="tabs is-boxed pb-5">
            <ul>
              <li className={getNavLinkClass("/tanggal-report")}>
                <Link to="/tanggal-report">
                  <span>Tanggal Report</span>
                </Link>
              </li>
              <li className={getNavLinkClass("/tanggal-report/data-generate")}>
                <Link to="data-generate">
                  <span>Data Total 5 Bulan</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <Outlet />
      </div>
    </section>
  );
};

export default TglReportRoot;
