import React, { useState, useEffect } from "react";

const Footer = () => {
  let date = new Date();
  let tahun = date.getFullYear();

  const [thnShow, setTglShow] = useState("");

  useEffect(() => {
    setTglShow(`${tahun}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="footer">
      <div className="container">
        <p>
          <strong>PSISIMKu</strong>
          <br />
          copyright&copy;{thnShow} | PT. MITRA LAYANAN DIGITAL
          <br />
          Allright Reserved
        </p>
      </div>
    </section>
  );
};

export default Footer;
