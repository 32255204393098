import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FaSignInAlt, FaUserAlt, FaKey } from "react-icons/fa";
import { API_URL_AUTH } from "../config/config";
import Ilustration1 from "../assets/images/illustration-7.png";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();

  const Auth = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${API_URL_AUTH}/admin/auth`, {
        username: username,
        password: password,
      });
      navigate("/");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.message);
      }
    }
  };

  return (
    <section className="hero is-fullheight is-fullwidth has-background-psisim">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-four-fifths">
              <div className="columns is-desktop">
                <div className="column">
                  <div className="card card-radius">
                    <div className="card-content">
                      <div className="content">
                        <h4 className="has-text-danger has-text-centered">
                          RHS MLD
                        </h4>
                        <p className="subtitle is-6 has-text-centered">
                          Silahkan login dibawah ini
                        </p>
                        <form onSubmit={Auth}>
                          {msg && (
                            <div className="notification is-success" msg={msg}>
                              <button type="button" className="delete"></button>
                              {msg}
                            </div>
                          )}

                          <div className="field mt-3">
                            <label className="label">Username</label>
                            <div className="control has-icons-left">
                              <input
                                type="text"
                                className="input"
                                placeholder="Username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                              />
                              <span className="icon is-left has-text-grey-lighter">
                                <FaUserAlt />
                              </span>
                            </div>
                          </div>
                          <div className="field mt-3">
                            <label className="label">Password</label>
                            <div className="control has-icons-left">
                              <input
                                type="password"
                                className="input"
                                placeholder="******"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                              />
                              <span className="icon is-left has-text-grey-lighter">
                                <FaKey />
                              </span>
                            </div>
                          </div>
                          <div className="field mt-5">
                            <button className="button has-icons-left is-info is-bold is-rounded is-fullwidth">
                              <span className="icon is-left">
                                <FaSignInAlt />
                              </span>
                              <span>Login</span>
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column is-three-fifths-desktop is-hidden-mobile">
                  <img src={Ilustration1} alt="ILL" className="imageLogin" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
