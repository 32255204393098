/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from "axios";
import jwtDecode from "jwt-decode";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import { API_URL_AUTH, API_UPLOAD } from "../config/config";
import { IconContext } from "react-icons";
import { FaKey, FaSave, FaUpload, FaUserEdit } from "react-icons/fa";

const Profile = () => {
  /* eslint-disable-next-line */
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");
  const [errors, setErrors] = useState([]);

  const [namaUser, setNamaUser] = useState("");
  const [username, setUsername] = useState("");
  const [fotoUser, setFotoUser] = useState("");
  const [fotoUpload, setFotoUpload] = useState("");

  const [passwordLama, setPasswordLama] = useState("");
  const [passwordBaru, setPasswordBaru] = useState("");
  const [passwordUlang, setPasswordUlang] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    refreshToken();
    loadAccount();
  }, []);

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${API_URL_AUTH}/admin/refreshtoken`);
      setToken(response.data.token);
      const decoded = jwtDecode(response.data.token);
      setExpire(decoded.exp);
    } catch (error) {
      if (error.response) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
          timer: 2000,
          timerProgressBar: true,
          showConfirmButton: false,
          willClose: () => {
            navigate("/login");
          },
        }).then((result) => {
          if (result.dismiss === Swal.DismissReason.timer) {
            navigate("/login");
          }
        });
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${API_URL_AUTH}/admin/refreshtoken`);
        config.headers.Authorization = `Bearer ${response.data.token}`;
        setToken(response.data.token);
        const decoded = jwtDecode(response.data.token);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const loadAccount = async () => {
    const response = await axiosJWT.get(`${API_URL_AUTH}/admin/my-account`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    setNamaUser(response.data.data.namaUser);
    setUsername(response.data.data.username);
    setFotoUser(response.data.data.fotoUser);
  };

  const updateAccount = async (e) => {
    e.preventDefault();

    Swal.fire({
      title: "Konfirmasi Update Account?",
      text: "Yakin ingin mengupdate account Anda!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Update!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axiosJWT.put(
            `${API_URL_AUTH}/admin/my-account`,
            {
              namaUser: namaUser,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          loadAccount();
          Swal.fire(
            "Account Updated!",
            "Account berhasil di update",
            "success"
          );
        } catch (error) {
          if (error.response.status === 422) {
            setErrors(error.response.data.errors);
          }

          Swal.fire("ERROR!", "Gagal melakukan update account", "error");
        }
      }
    });
  };

  const updatePassword = async (e) => {
    e.preventDefault();

    Swal.fire({
      title: "Konfirmasi Update Password?",
      text: "Yakin ingin mengubah password Anda!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Update!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axiosJWT.put(
            `${API_URL_AUTH}/admin/ganti-password`,
            {
              passwordLama: passwordLama,
              passwordBaru: passwordBaru,
              passwordUlang: passwordUlang,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          loadAccount();
          Swal.fire(
            "Account Updated!",
            "Account berhasil di update",
            "success"
          );
        } catch (error) {
          if (error.response.status === 422) {
            setErrors(error.response.data.errors);
          } else {
            Swal.fire("ERROR!", error.response.data.message, "error");
          }
        }
      }
    });
  };

  const loadImage = (e) => {
    const image = e.target.files[0];
    setFotoUpload(image);
    setFotoUser(URL.createObjectURL(image));
  };

  const updateFoto = async (e) => {
    e.preventDefault();
    const fileName = new Date().getTime() + "-adeavatar.jpeg";

    await axiosJWT
      .put(
        `${API_URL_AUTH}/admin/my-photo`,
        {
          fotoUser: fileName,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        const formData = new FormData();
        formData.append("photo", fotoUpload);
        formData.append("fileName", fileName);
        formData.append("folder", "avatars");

        axios.post(`${API_UPLOAD}/upload-single-blob`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        Swal.fire({
          title: "Sukses!",
          text: response.data.message,
          icon: "success",
          timer: 2000,
          timerProgressBar: true,
          showConfirmButton: false,
        });
      })
      .catch((error) => {
        if (error.response.status === 422) {
          setErrors(error.response.data.errors);
          Swal.fire({
            icon: "warning",
            title: "Oops...",
            text: error.response.data.message,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.response.data.message,
          });
        }
      });
  };

  return (
    <section className="section is-fullwidth">
      <div className="container">
        <div className="labelPage2 has-background-black">
          <div className="iconLabel2">
            <IconContext.Provider
              value={{
                className: "icon is-large has-text-white",
              }}
            >
              <FaUserEdit />
            </IconContext.Provider>
          </div>
          <div className="vertical-center">
            <h1 className="title ml-6 is-6 has-text-white">UPDATE AKUN</h1>
            <p className="subtitle is-7 ml-6 has-text-white">
              Update data profile akun
            </p>
          </div>
        </div>

        <div className="boxCard">
          <nav className="breadcrumb is-small" aria-label="breadcrumbs">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="is-active">
                <Link href="#" aria-current="page">
                  Profile
                </Link>
              </li>
            </ul>
          </nav>
          <div className="is-flex is-flex-direction-row is-justify-content-center">
            <div className="is-flex-grow-1 mr-4">
              <h4 className="has-text-danger text-bold">My Account</h4>
              <hr className="mt-0" />
              <form onSubmit={updateAccount}>
                <div className="field is-horizontal">
                  <div className="field-label is-small">
                    <label className="label">Nama Lengkap</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <p className="control is-expanded">
                        <input
                          className="input is-small"
                          type="text"
                          value={namaUser}
                          onChange={(e) => setNamaUser(e.target.value)}
                          placeholder="Nama lengkap user"
                        />
                      </p>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-label is-small">
                    <label className="label">Username</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <p className="control">
                        <input
                          className="input is-small"
                          type="text"
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                          placeholder="username"
                          disabled
                        />
                      </p>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-label">&nbsp;</div>
                  <div className="field-body">
                    <div className="field is-grouped">
                      <div className="control">
                        <button
                          type="submit"
                          className="button is-link is-small is-rounded"
                        >
                          <span className="icon">
                            <FaSave />
                          </span>
                          <span>Update Akun</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>

              <h4 className="has-text-danger text-bold">Change Password</h4>
              <hr className="mt-0" />
              <form onSubmit={updatePassword}>
                <div className="field is-horizontal">
                  <div className="field-label is-small">
                    <label className="label">Password Lama</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <p className="control is-expanded">
                        <input
                          className="input is-small"
                          type="password"
                          value={passwordLama}
                          onChange={(e) => setPasswordLama(e.target.value)}
                          placeholder="***"
                        />
                      </p>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-label is-small">
                    <label className="label">Password Baru</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <p className="control is-expanded">
                        <input
                          className="input is-small"
                          type="password"
                          value={passwordBaru}
                          onChange={(e) => setPasswordBaru(e.target.value)}
                          placeholder="***"
                        />
                      </p>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-label is-small">
                    <label className="label">Ulangi Password Baru</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <p className="control is-expanded">
                        <input
                          className="input is-small"
                          type="password"
                          value={passwordUlang}
                          onChange={(e) => setPasswordUlang(e.target.value)}
                          placeholder="***"
                        />
                      </p>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-label">&nbsp;</div>
                  <div className="field-body">
                    <div className="field is-grouped">
                      <div className="control">
                        <button
                          type="submit"
                          className="button is-info is-small is-rounded"
                        >
                          <span className="icon">
                            <FaKey />
                          </span>
                          <span>Ganti Password</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>

              <div>
                {errors && (
                  <>
                    {errors.map((error, index) => (
                      <p key={index} className="help is-danger">
                        - {error.msg}
                      </p>
                    ))}
                  </>
                )}
              </div>
            </div>
            <div>
              <h4 className="has-text-danger text-bold">My Photo</h4>
              <hr className="mt-0" />
              <figure className="image is-128x128">
                <img
                  className="is-rounded is-centered"
                  src={fotoUser}
                  alt={namaUser}
                />
              </figure>

              <form onSubmit={updateFoto} className="mt-2">
                <div className="file is-warning is-boxed">
                  <label className="file-label">
                    <input
                      className="file-input"
                      type="file"
                      onChange={loadImage}
                      name="fotoUser"
                    />
                    <span className="file-cta">
                      <span className="file-icon">
                        <FaUpload />
                      </span>
                      <span className="file-label">Uplaod Foto</span>
                    </span>
                  </label>
                </div>
                <button
                  type="submit"
                  className="button is-success mt-2 is-small is-rounded is-light is-fullwidth"
                >
                  <span className="icon">
                    <FaSave />
                  </span>
                  <span>Simpan Foto</span>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Profile;
