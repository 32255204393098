/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from "axios";
import jwtDecode from "jwt-decode";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import { FaPlay } from "react-icons/fa";
import { API_URL_AUTH } from "../../config/config";

const GenerateIndex = () => {
  /* eslint-disable-next-line */
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");

  const navigate = useNavigate();

  const [generate, setGenerate] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const btnLoading = isLoading ? "is-loading" : "";
  /* eslint-disable-next-line */

  useEffect(() => {
    refreshToken();
    getAllGenerate();
  }, []);

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${API_URL_AUTH}/admin/refreshtoken`);
      setToken(response.data.token);
      const decoded = jwtDecode(response.data.token);
      setExpire(decoded.exp);
    } catch (error) {
      if (error.response) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
          timer: 2000,
          timerProgressBar: true,
          showConfirmButton: false,
          willClose: () => {
            navigate("/login");
          },
        }).then((result) => {
          if (result.dismiss === Swal.DismissReason.timer) {
            navigate("/login");
          }
        });
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${API_URL_AUTH}/admin/refreshtoken`);
        config.headers.Authorization = `Bearer ${response.data.token}`;
        setToken(response.data.token);
        const decoded = jwtDecode(response.data.token);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getAllGenerate = async () => {
    await axiosJWT
      .get(`https://srvpay1.psisim.net/admin/laporan-5bulan`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setGenerate(response.data.data);
      });
  };

  const goGenerate = async () => {
    setIsLoading(true);
    await axiosJWT
      .get(`https://srvpay1.psisim.net/admin/generate5bulan`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setIsLoading(false);
        getAllGenerate();
        Swal.fire("Generate!", response.data.message, "success");
      })
      .catch((error) => {
        Swal.fire("Generate!", error.response.data.message, "error");
      });
  };

  return (
    <>
      <nav className="breadcrumb is-small" aria-label="breadcrumbs">
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li className="is-active">
            <Link href="#" aria-current="page">
              Data Tanggal Report
            </Link>
          </li>
        </ul>
      </nav>

      <div className="columns is-vcentered is-desktop">
        <div className="column is-4">
          <div className="buttons">
            <a
              onClick={() => goGenerate()}
              className={`button is-success is-small is-rounded ${btnLoading}`}
            >
              <span className="icon">
                <FaPlay />
              </span>
              <span className="is-hidden-mobile">Generate</span>
            </a>
          </div>
        </div>
      </div>

      {generate ? (
        <>
          <div className="table-container">
            <table className="table is-striped is-bordered is-fullwidth is-hoverable is-size-7">
              <thead className="table-header">
                <tr>
                  <th>No.</th>
                  <th>Bulan Report</th>
                  <th>Total</th>
                  <th>Total Arsip</th>
                </tr>
              </thead>
              <tbody>
                {generate.map((gnr, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{gnr.blnReport}</td>
                    <td>{gnr.total}</td>
                    <td>{gnr.totalarsip}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <div className="is-fullwidth has-text-centered mt-5">
          <figure className="image is-2by2 is-inline-block">
            <img
              src="https://plan.literasikitaindonesia.com/kasir/no-data-found.png"
              alt="logo cart"
            />
          </figure>
          <h4>Data tidak ditemukan</h4>
        </div>
      )}
    </>
  );
};

export default GenerateIndex;
